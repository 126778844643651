<template>
  <div>
    <app-header />

    <section class="gifting-services container">
      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <h1 class="gifting-services__title">
              Custom gifting<br />services for<br />
              on-demand delivery.
            </h1>

            <p class="gifting-services__text">
              Order a thoughtful gift for any occasion and we will prepare and
              deliver it promptly. Whether you prefer the gift be delivered to
              your default address or directly to the intended recipient, we
              have you covered. For your convenience, we offer the option to add
              an additional delivery address to a gift during checkout, allowing
              for delivery to more than one address per order.<br /><br />
              Please contact our customer support team for questions about our
              gifting services or to make a corporate or special event request.
            </p>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '650px' : '350px'"
              :interval="4000"
            >
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_1.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_2.png" />
                </div>
              </el-carousel-item>
              <!--<el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_3.png" />
                </div>
              </el-carousel-item>-->
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_4.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_5.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_6.png" />
                </div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center">
                  <img src="../assets/car1_7.png" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <img src="../assets/gifts_1.png" />
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Order directly through<br />
                your RoadRunner<br />
                account.
              </h1>

              <p class="gifting-services__text">
                Customize your gifts your way. 
                Choose from one of our classically themed wrapping sets or mix and match colours to create a custom gift. 
                Enjoy the convenience of ordering as many products as you like from as many retailers as you like. 
                Choose which products are gifts and customize each one separately.<br /><br />
                Prices for gift wrap sets and greeting cards are listed on our platform.
              </p>

              <div class="gifting-services__download-buttons">
                <img
                  class="gifting-services__download"
                  src="../assets/dow_apple.png"
                />
                <img
                  class="gifting-services__download"
                  src="../assets/dow_google.png"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <h1 class="gifting-services__title">
              Gift Wrap<br />
              Sets
            </h1>

            <p class="gifting-services__text">
              Our elegant gift wrap is supplied by Fleur Wholesale, a Canadian small business. 
              Most gifts are placed in a black kraft paper bag which is eco-friendly, 
              thick and durable with a soft fabric handle. Our bags come in different sizes 
              to snugly fit your gift. All our wrapping paper, tissue paper and wrapping gauze 
              are thick textured, smooth to the touch and water resistant.<br /><br />
              Looking for a specific colour combination for your gift? No problem, 
              simply include your preferences in your order notes or contact us directly 
              to notify us of your request.
            </p>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div v-if="isDesktop">
              <br /><br />
            </div>

            <img src="../assets/car1_3.png" />
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col class="el-col--mobile" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Camel & Coffee
              </h1>

              <p class="gifting-services__text">
                Neutrally toned, this classic set is modern and elegant, 
                perfect for any occasion. This set includes our double-sided camel and 
                coffee matte wrapping paper, sheer white tissue and is finished with our 
                rustic grey fabric ribbon tied into an elegant bow.
              </p>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div v-if="isDesktop">
            </div>

            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '400px' : '230px'"
              :interval="4000"
            >
              <el-carousel-item>
                <img class="carousel-img" src="../assets/car2_1.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="carousel-img" src="../assets/car2_2.png" />
              </el-carousel-item>
            </el-carousel>
          </el-col>

          <el-col class="el-col--desktop" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Camel & Coffee
              </h1>

              <p class="gifting-services__text">
                Neutrally toned, this classic set is modern and elegant, 
                perfect for any occasion. This set includes our double-sided camel and 
                coffee matte wrapping paper, sheer white tissue and is finished with our 
                rustic grey fabric ribbon tied into an elegant bow.
              </p>
            </div>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-left">
              <h1 class="gifting-services__title">
                Charcoal & Turkish
              </h1>

              <p class="gifting-services__text">
                Cool toned, this chic and sophisticated set is perfect 
                for year-round gifting with a refined touch. This set 
                includes our double-sided charcoal and turkish matte wrapping paper, 
                sheer grey tissue, grey gauze wrap and is finished with our ruffled ivory, 
                grey chiffon or grey vintage ribbon tied into an elegant bow.
              </p>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div v-if="isDesktop">
            </div>

            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '400px' : '230px'"
              :interval="4000"
            >
              <el-carousel-item>
                <div class="gifting-services__center"><img class="carousel-img" src="../assets/coal_turkish1.png" /></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="gifting-services__center"><img class="carousel-img" src="../assets/coal_turkish2.png" /></div>
              
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col class="el-col--mobile" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Red & Gold
              </h1>

              <p class="gifting-services__text">
                Warm toned, this vibrant set is excellent for the cold winter months, 
                holiday season or as a romantic statement. This set includes our double-sided 
                red and gold shimmery wrapping paper, sheer red tissue and is finished 
                with our ruffled red, ruffled peach or grey fabric ribbon tied into an elegant bow.
              </p>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div v-if="isDesktop">
            </div>

            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '400px' : '230px'"
              :interval="4000"
            >
              <el-carousel-item>
                <img class="carousel-img" src="../assets/red_gold1.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img class="carousel-img" src="../assets/red_gold2.png" />
              </el-carousel-item>
            </el-carousel>
          </el-col>

          <el-col class="el-col--desktop" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-right">
              <h1 class="gifting-services__title">
                Red & Gold
              </h1>

              <p class="gifting-services__text">
                Warm toned, this vibrant set is excellent for the cold winter months, 
                holiday season or as a romantic statement. This set includes our double-sided 
                red and gold shimmery wrapping paper, sheer red tissue and is finished 
                with our ruffled red, ruffled peach or grey fabric ribbon tied into an elegant bow.
              </p>
            </div>
          </el-col>
        </el-row>
      </section>

      <section class="gifting-services__section">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="gifting-services__to-left">
              <h1 class="gifting-services__title">
                Greeting Cards
              </h1>

              <p class="gifting-services__text">
                Our elegant custom-made greeting cards are handmade in small
                batches by Vita Beata Boutique, a small Canadian business
                focused on environmental sustainability. All cards are plantable
                - embedded with wildflower seeds and are paired with an elegant
                brown kraft paper envelope. Our cards are fully biodegradable,
                recyclable, non-toxic, plastic free, and made with 100%
                post-consumer recycled materials. To better suit our customer’s
                needs, our cards are gender and religion neutral. Each card is
                made with love, designed and hand-drawn or painted by the
                talented artist at Vita Beata Boutique. We hope you love these
                cards as much as we do!<br /><br />
                When adding one of our cards to an order, customers can choose
                the option of either a blank or personalized card. Blank cards
                will be delivered as is, with a kraft paper envelope. When
                personalizing a card, we will hand write your desired message
                inside the card and encase it in its envelope. When handwriting
                a personalized message in a card, we will do our best to match
                the customer’s desired formatting as displayed in the text box
                of the mobile app. Personalized cards being paired with a
                wrapped gift will be neatly attached to the gift for delivery.
              </p>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <br /><br />
            <br /><br />
            <br /><br />
            <el-carousel
              indicator-position="outside"
              :height="isDesktop ? '550px' : '350px'"
              :interval="4000"
            >
              <el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Birthday.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Anniversary.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Congratulations.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Best Wishes.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/GetWell.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Sympathy.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/ThankYou.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img
                  src="../assets/Engagement & Wedding.png"
                /> </div></el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Shower&NewBaby.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Valentines.png" /></div> </el-carousel-item
              ><el-carousel-item>
                <div class="gifting-services__center"><img src="../assets/Season'sGreetings.png" /></div> </el-carousel-item
              >
            </el-carousel>
          </el-col>
        </el-row>
      </section>
    </section>

    <app-footer />
  </div>
</template>

<script>
  import AppHeader from '../components/RoadrunnerLanding/AppHeader'
  import AppFooter from '../components/RoadrunnerLanding/AppFooter'

  export default {
    name: 'GiftingServices',

    components: {
      AppHeader,
      AppFooter,
    },

    data() {
      return {
        isDesktop: window.innerWidth >= 992
      }
    }
  }
</script>

<style lang="scss" scoped>
  .carousel-img{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .el-col--desktop{
    @media (max-width: 1200px){
      display: none;
    }
  }

  .el-col--mobile{
    @media (min-width: 1201px){
      display: none;
    }
  }

  .gifting-services {
    margin-top: 160px;
    box-sizing: border-box;
    text-align: left;
    font-family: 'CabinetGrotesk-Regular';

    @media (max-width: 991px) {
      max-width: 500px;
    }

    &__section {
      margin-bottom: 128px;

      @media (max-width: 991px) {
        margin-bottom: 78px;
      }
    }

    &__title {
      font-family: 'CabinetGrotesk-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 74px;
      color: #000000;
      max-width: 585px;
      margin-bottom: 42px;

      @media (max-width: 991px) {
        font-size: 36px;
        line-height: 45px;
        margin-top: 20px;
      }
    }

    &__text {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 300;
      font-size: 21px;
      line-height: 42px;
      color: #000000;
      max-width: 500px;

      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    &__to-right {
      @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }

    &__download-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      @media (max-width: 991px) {
        justify-content: flex-start;
      }
    }

    &__download {
      margin-top: 32px;
      margin-right: 8px;
      cursor: pointer;
      width: unset !important;

      @media (max-width: 1200px) {
        margin-top: 0px;
        width: 136px !important;
      }
    }

    &__center {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    img {
      border-radius: 2px;
      max-width: 100%;
    }
  }
</style>
